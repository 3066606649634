/**
 * Buttons
 */
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    text-decoration: none;
    color: inherit;
    border: 0;
    box-shadow: unset;
    cursor: pointer;
    transition: all ease-in-out .2s;

    &-primary {
      @include p5;
      line-height: 1;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #fff;
      background-color: $clrLightBlue;
      padding: .8em 1em;

      &:hover {
        opacity: .7;        
      }

      i, .svg-inline--fa {
        margin-left: .5em;
      }
    }
  }

/**
 * Separators
 */
hr {
  border: 0;

  &.title-sep {
    width: 70px;
    height: 0;
    border-bottom: 5px solid $clrLightBlue;
    margin: 1rem 0;
  }

  &.page-sep {
    width: calc(100% - 3rem);
    height: 0;
    border-bottom: 5px dashed $clrAzure;
    margin-bottom: 4rem;
    @media screen and (max-width: 767px) {
      width: calc(100% - 2.5rem);
      margin-bottom: 1.5rem;
    }
  }
}

/**
 * Swipers
 */
 .swiper {
  height: 100%;

  .swiper-slide {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
 }

.swiper-buttons {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 40px;
  margin: 1.5rem 0;
}

.swiper-button {
  &-next, &-prev {
    @include h5;
    line-height: 1;
    position: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $clrLightBlue;
    padding: .5em;
    border: 1px solid $clrAzure;
    border-radius: 100%;
    width: 40px;
    height: 40px;   
    background-color: #fff;
    
    &:after {
      content: none;
    }
  }

  &-prev {
    margin-right: 1rem;
  }
}

/**
 * Lightbox
 */
 .modal-img-lightbox {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;  
  
  &.is-open {
    display: flex !important;
  }
  
  .lightbox-overlay {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  figure {
    position: relative;
    z-index: 10;
    margin: 0;
    width: auto;
    height: auto;

    .lightbox-close {
      @include p2;
      line-height: 1;
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      font-weight: 700;
      background-color: $clrLightBlue;
      color: #fff;
      padding: .25rem 1rem .4rem; 
      cursor: pointer;
      transition: all ease-in-out .2s;  
      
      &:hover {
        @media (hover: hover) {
          opacity: .75;
        }
      }
    }

    img {
      max-width: 90vw;
      max-height: 80vh;
    }
  }
 }

 /**
  * WPML BUTTONS
  */  
  .otgs-development-site-front-end {
    display: none;
  }

  .main-nav, .site-footer {
    .wpml-ls-item {
      a {
        width: fit-content;
        font-size: .8rem;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        padding: .4em 1em !important;
        background-color: $clrLightBlue;
  
        &:hover {
          @media (hover: hover) {
            color: #fff;
            opacity: .7;
          }
        }
      }
    }
  }
  
  .main-nav {
    .wpml-ls-item {
      a {
        margin-left: 1rem;

        @media screen and (max-width: 991px) {
          margin-left: 0;
          margin-top: 1rem;
          @include p4;
        }
      }
    }
  }

  .site-footer {
    .wpml-ls-item {
      a {
        margin-top: .5rem;
      }
    }
  }

  iframe {
    width: 100%;
  }