// abstract
@import "./abstract/variables";
@import "./abstract/mixins";
@import "./abstract/type";
@import "./abstract/ui";
@import "./abstract/contact-form";

// template-parts
@import "./template-parts/page-header";
@import "./template-parts/content-section";
@import "./template-parts/entries";

// nav
@import "./nav/main-nav";
@import "./nav/footer";

// page-templates
@import "./page-templates/home";
@import "./page-templates/about";
@import "./page-templates/news";
@import "./page-templates/patologie";
@import "./page-templates/testimonials";
@import "./page-templates/book";
@import "./page-templates/single";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;  
}

img {
  display: block;
  width: 100%;
  max-width: 100%;  
  height: auto;
  object-fit: contain;
  object-position: center;
}

figure, figcaption {
  margin-bottom: 0;
}

html, body {
  width: 100%;
  height: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;  
}

body {
  &.overflow-hidden {
    height: 100%;
    max-height: 100vh;
    overflow: hidden;
  }
}

.app, .page-sep, .site-footer {
  max-width: 1920px;
  overflow-x: hidden;   
  margin: 0 auto;
}

.app {
  main {
    scroll-margin-top: 85px;
  }
  &.page-error {
    h1, p {
      text-align: center;      
    }

    p {
      @include p3;
      max-width: 600px;
      margin-right: auto;
      margin-left: auto;

      &:last-of-type {
        margin-bottom: 1.5rem;
      }
    }

    .btn {
      margin-right: auto;
      margin-left: auto;
    }
  }
}