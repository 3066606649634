.home {
  main {
    h2 {
      margin-bottom: 0;
    }
  }
}

.home-header {  
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  margin-bottom: 6rem;

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    margin-bottom: 3rem;
  }
  
  .header-txt, figure {
    flex: 0 0 50%;
  }

  .header-txt {
    padding: 85px 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
    @media screen and (max-width: 767px) {
      padding: 1.2rem;
    }
  }

  h1 {
    @include h1;
    line-height: 1;
    margin-top: auto;
    margin-bottom: 1.4rem;
    max-width: 90%;

    @media screen and (max-width: 767px) {
      margin-bottom: 1.2rem;
    }
  }
  
  p {
    @include p4;
    max-width: 600px;
  }

  .arrow-scroll-down {
    text-decoration: none;    
    display: block;
    width: auto;
    height: 70px;
    margin-top: auto;
    color: $clrBlack;

    @media screen and (max-width: 767px) {
      margin-top: 2rem;
    }

    &:hover {
      img {
        transform: translateY(.3rem);
      }
    }

    @keyframes scrollDownCTA {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(.3rem);
      }
      100% {
        transform: translateY(0);
      }
    }

    figure {
      height: 100%;
      display: flex;
      align-items: center;
    }

    img {
      pointer-events: none;
      user-select: none;
      height: 100%;
      width: auto;
      margin-right: 1rem;
      transition: all ease-in-out .2s;
      animation: scrollDownCTA infinite 1s;
      opacity: .8;
    }
  }

  .header-img {
    min-height: 100vh;
    background-color: $clrLightBlue;

    @media screen and (max-width: 767px) {
      min-height: unset;
      padding-top: 85px;
    }
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: .7;
    }    
  }
}

.about {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  background-color: rgba($clrAzure, .6);
  margin-top: 8rem;
  padding: 4rem 0 2.5rem;

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    margin-top: 0;
    padding: 2rem 1.25rem 0;    
  }

  figure {
    flex: 0 0 50%;
    padding-right: 2rem;
    margin-top: -6rem;
    margin-bottom: -2.5rem;
    align-self: flex-end;
    position: relative;

    @media screen and (max-width: 767px) {
      margin-top: 0;
      margin-bottom: 0;
    }

    img {
      width: 50%;
      max-width: 300px;
      margin-left: auto;

      @media screen and (max-width: 767px) {
        width: 75%;
        max-width: unset;
      }
    }
  }

  section {
    flex: 0 0 calc(50% - 1.5rem);
    background-color: rgba(255,255,255,.5);
    padding: 2rem;
    max-width: calc(600px + 3rem); 
    margin-bottom: 2.5rem;
    
    @media screen and (max-width: 767px) {
      flex-basis: 100%;
      max-width: unset;
      padding: 1.25rem;
      margin-bottom: 2rem;
    }
  }

  header {
    margin-bottom: 1.5rem;
    @media screen and (max-width: 767px) {
      margin-bottom: 1rem;
    }
  }  

  h3 {
    @include p5;
    font-family: "Jost", sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
  }  

  p {
    &:first-of-type {
      &:first-letter {
        display: inline-block;
        @include h1;        
        text-transform: capitalize;
        margin-right: .3rem;
      }
    }    
  }

  .btn-primary {
    margin-top: 1.5rem;
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}

.foundation-wrap {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  min-height: 70vh;

  @media screen and (max-width: 767px) {
    display: block;
  }

  figcaption {
    flex: 0 0 50%;
    padding: 4rem 2rem 4rem 1.5rem;
    max-width: 600px;
    margin: auto;
    @media screen and (max-width: 767px) {
      max-width: unset;
      padding: 2rem 1.25rem 2rem;
    }    
  }  

  figure {
    flex: 0 0 50%;
    background-color: $clrLightBlue;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media screen and (max-width: 767px) {
      padding: 2rem;
    }
  }

  .foundation-bg {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: .2;
  }

  .foundation-logo {
    position: relative;
    z-index: 10;
    width: 200px;
    border-radius: .5rem;
  }
}

.testimonials {
  margin: 5rem 0 3rem;
  padding: 0 1.5rem;

  @media screen and (max-width: 767px) {
    margin: 3rem 0 2rem;
    padding: 0 1.25rem;
  }
  
  header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 767px) {
      display: block;
      margin-bottom: 1.5rem;
    }

    h2 {
      padding-right: 2rem;
      margin-right: 2rem;
      margin-bottom: 0;
      border-right: 1px solid $clrLightBlue;
      @media screen and (max-width: 767px) {
        padding-right: 0;
        border-right: 0;
        margin-right: 0;
        margin-bottom: .5rem;
      }
    }
  }

  .title-sep {
    margin-bottom: 2.5rem;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .swiper {
    height: auto;
  }

  .swiper-wrapper {
    align-items: stretch;
    height: auto;
    padding-bottom: 2rem;
  }

  .swiper-slide {
    border: 1px solid $clrAzure;
    box-shadow: 3px 3px 0 3px $clrAzure;
    padding: 1.5rem;
    height: inherit;    
  }

  article {
    height: 100%;
    display: flex;
    flex-direction: column;

    header {
      display: flex;
      flex-wrap: nowrap;      
      align-items: center;
      margin-bottom: 1rem;

      figure {
        padding-right: 1rem;
        border-right: 1px solid $clrAzure;
        margin-right: 1rem;
        flex-shrink: 0;
      }

      img {
        width: 3rem;
        height: 3rem;
        border-radius: 100%;
        object-fit: cover;      
        flex-shrink: 0;
      }

      h3 {
        @include h5;
        margin-bottom: 0;
      }
    }
  }

  blockquote {
    margin-top: .5rem;
    margin-bottom: 1.5rem;
    position: relative;   
    padding-right: 1rem;
    
    &:before, &:after {
      position: absolute;
      @include h1;
      font-weight: 400;
      font-family: "Gelasio", serif;
      opacity: .9;
    }

    &:before {
      content: "\201C";
    }    

    &:after {
      content: "\201E";
      bottom: 0;
      right: 0;
      transform: translateY(2px);
    }
  }

  p {    
    &::first-letter {
      display: inline-block;
      @include h1;        
      text-transform: capitalize;
      margin-right: .4rem;     
      margin-left: 1rem; 
    }
  }

  .article-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;

    span {
      display: block;
      width: .5rem;
      height: .5rem;
      margin: .5rem;
      border-radius: 100%;
      background-color: $clrAzure;
    }
  }  
}

.clinics {
  display: flex;
  padding: 1.5rem;
  margin: 4rem 0;

  @media screen and (max-width: 767px) {
    display: block;
    margin: 2rem 0 2rem;
    padding: 0 1.25rem;
  }

  > section {
    flex: 0 0 50%;

    header {
      margin-bottom: 2rem;
      @media screen and (max-width: 767px) {
        margin-bottom: 1.5rem;
      }
    }
  }

  .btn-primary {
    @media screen and (max-width: 767px) {
      margin-bottom: 1.5rem;
    }
  }

  &-cards {
    padding-right: 1rem;

    @media screen and (max-width: 767px) {
      padding-right: 0;
      margin-bottom: 3rem;
    }

    article {
      padding: 1.5rem;
      background-color: rgba($clrAzure, .4);   
      margin-bottom: 1.5rem;
      @media screen and (max-width: 767px) {
        margin-bottom: 1rem;
      }
    }

    h3 {
      @include h4;
      margin-bottom: .5rem;
    }

    address {
      margin-bottom: .5rem;
    }
  }

  &-form {
    padding-left: 1rem;
    @media screen and (max-width: 767px) {
      padding-left: 0;
    }
  }
}