.page-header {
  margin-bottom: 1.5rem;

  @media screen and (max-width: 767px) {
    margin-bottom: 1.25rem;
  }

  img {
    width: 100%;
    height: clamp(150px, 40vh, 300px);
    object-fit: cover;
    margin-bottom: 1.5rem;
    
    @media screen and (max-width: 767px) {
      margin-bottom: 1.25rem;
    }
  }

  figcaption {
   padding: 0 1.5rem; 

   @media screen and (max-width: 767px) {
    padding: 0 1.25rem;
   }
  }

  h1 {
    @include h2;
  } 
}