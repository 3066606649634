/**
 * Google fonts
 */
@import url('https://fonts.googleapis.com/css2?family=Gelasio:ital,wght@0,400;0,700;1,400;1,700&family=Jost:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap');

/**
 * Fontawesome
 */
@font-face {
  font-family: 'Font Awesome 6 Brands';
  src: url($resolvepath +'fonts/fontawesome/fa-brands-400.ttf');
  src: url($resolvepath +'fonts/fontawesome/fa-brands-400.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Font Awesome 6 Regular';
  src: url($resolvepath +'fonts/fontawesome/fa-regular-400.ttf');
  src: url($resolvepath +'fonts/fontawesome/fa-regular-400.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Font Awesome 6 Solid';
  src: url($resolvepath +'fonts/fontawesome/fa-solid-900.ttf');
  src: url($resolvepath +'fonts/fontawesome/fa-solid-900.woff2') format('woff2');
  font-weight: 900;
}

/**
 * Headings
 */
@mixin h1 {
  font-size: clamp(2.4rem, 3.5vw, 4rem);
  line-height: 1;
  @media screen and (max-width: 767px) {
    font-size: 2.2rem;
  }
}

@mixin h2 {
  font-size: clamp(2.2rem, 2.2vw, 3rem);
  line-height: 1;
  @media screen and (max-width: 767px) {
    font-size: 2rem;
  }
}

@mixin h3 {
  font-size: clamp(1.8rem, 1.8vw, 2.2rem);
  line-height: 1.2;

  @media screen and (max-width: 767px) {
    font-size: 1.8rem;
  }
}

@mixin h4 {
  font-size: clamp(1.6rem, 1.6vw, 1.8rem);
  line-height: 1.2;
  @media screen and (max-width: 767px) {
    font-size: 1.6rem;
  }
}

@mixin h5 {
  font-size: clamp(1.4rem, 1.4vw, 1.6rem);
  line-height: 1.2;
  @media screen and (max-width: 767px) {
    font-size: 1.4rem;
  }
}

@mixin h6 {
  font-size: clamp(1.2rem, 1.2vw, 1.4rem);
  line-height: 1.4;
  @media screen and (max-width: 767px) {
    font-size: 1.2rem;
  }
}

/**
 * Body
 */
@mixin p1 {
  font-size: clamp(1.8rem, 2vw, 2.2rem);
  line-height: 1.2;
}

@mixin p2 {
  font-size: clamp(1.4rem, 1.5vw, 1.6rem);
  line-height: 1.2;
}

@mixin p3 {
  font-size: clamp(1.2rem, 1.2vw, 1.4rem);
  line-height: 1.4;
}

@mixin p4 {
  font-size: clamp(1rem, 1vw, 1.2rem);
  line-height: 1.4;
}

@mixin p5 {
  font-size: clamp(.8rem, 1vw, 1rem);
  line-height: 1.4;
}

@mixin p6 {
  font-size: clamp(.6rem, 1vw, .8rem);
  line-height: 1.4;
}

/**
 * Actual styling
 */
h1, h2, h3, h4, h5, h6 {
  font-family: "Gelasio", serif;
  font-weight: 400;
}

body {
  font-family: "Jost", sans-serif;
  @include p4;
  color: $clrBlack;
}

.app {
  h2 {
    @include h2;
  }
  h3 {
    @include h3;
  }
} 

main {
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1rem;
    @media screen and (max-width: 767px) {
      margin-bottom: .8rem;
    }
  }
  h1 {
    @include h1;
  }
  h2 {
    @include h3;
  }
  h3 {
    @include h5;
  }
  h4 {
    @include h6;
  }
  h5 {
    @include h1;
  }
  h6 {
    @include p2;
  }

  blockquote, p, ul, ol, pre {
    margin-bottom: 1.5rem;

    @media screen and (max-width: 991px) {
      margin-bottom: .8rem;
    }
  
    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    ~ ul, ol {
      margin-top: 1.5rem;
    }
  }

  pre {
    white-space: break-spaces;
  }

  a {
    color: darken($clrLightBlue, 10);
    font-weight: 700;
    text-decoration-color: $clrLightBlue;
    transition: all ease-in-out .2s;

    &:hover {
      opacity: .75;
    }
  }

  ul, ol {
    padding-left: 1.5rem;    

    li {
      margin: .3rem 0;
    }
  }
}