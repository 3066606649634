.page-book {
  .page-header {
    margin-top: calc(85px + 2.5rem);    
    
    @media screen and (max-width: 767px) {
      margin-top: calc(85px + 1.5rem);
    }    

    img {
      width: 768px;
      max-width: calc(100% - 2.5rem);
      height: auto;
      margin: 0 auto 2.5rem;
      
      @media screen and (max-width: 767px) {
        margin: 0 auto 1.5rem;
      }    
    }
  }

  .content-section {
    > * {
      flex: 0 0 auto;
    }

    > figure {
      margin-left: auto;
      width: 600px;
      max-width: 50%;
      @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 100%;
      }

      img {
        width: auto;
        height: 100%;
        max-height: 60vh;
        margin-left: auto;

        @media screen and (max-width: 767px) {
          width: 50%;
          height: auto;
          max-height: unset;
          margin-right: auto;
        }
      }
    }
    
    .text {
      width: 600px;
      max-width: 50%;
      margin-right: auto;
      @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.book-stores {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + .5rem);
  margin-left: -.5rem;
  margin-right: -.5rem;

  @media screen and (max-width: 767px) {
    margin-top: 1.5rem;
  }

  header {
    flex-basis: 100%;
    padding-left: .5rem;
    @media screen and (max-width: 767px) {
      margin-bottom: 0 !important;
    }
  }

  .single-store {
    flex: 0 0 calc((100% / 3) - 1rem);
    margin: .5rem;
  }
}

.book-map {
  padding: 0 1.5rem;
  margin: 4rem 0;
  
  @media screen and (max-width: 767px) {
    margin: 2rem 0;        
    padding: 0 1.25rem;
  }

  header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 767px) {
     display: block;   
    }

    h2 {
      padding-right: 2rem;
      margin-right: 2rem;
      margin-bottom: 0;
      border-right: 1px solid $clrLightBlue;

      @media screen and (max-width: 767px) {
       padding-right: 0;
       margin-right: 0;
       border-right: 0; 
       margin-bottom: .5rem;
      }
    }    
  }

  hr {
    @media screen and (max-width: 767px) {
      display: none;        
    }
  }
}

.map {
  width: 768px;
  max-width: calc(100% - 2.5rem);
  margin: 1.5rem auto 0;

  @media screen and (max-width: 767px) {
    width: 100%;
    max-width: 100%;        
  }

  a {
    margin-top: 1rem;
  }
}