.single-testimonial {
  display: flex;
  flex-direction: column;
  border: 1px solid $clrAzure;
  box-shadow: 3px 3px 0 3px $clrAzure;
  padding: 1.5rem;
  align-self: stretch;

  header {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: nowrap;

    figure {
      flex-shrink: 0;
      padding-right: 1rem;
      border-right: 1px solid $clrAzure;
      margin-right: 1rem;
    }

    img {
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      object-fit: cover;      
      flex-shrink: 0;
    }

    h3 {
      @include h5;
      margin-bottom: 0;
    }
  }

  blockquote {
    margin-top: .5rem;
    margin-bottom: 1.5rem;
    position: relative;   
    padding-right: 1rem;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    
    &:before, &:after {
      position: absolute;
      @include h1;
      font-weight: 400;
      font-family: "Gelasio", serif;
      opacity: .9;
    }

    &:before {
      content: "\201C";
      top: 0;
      left: 0;
      position: relative;
      display: block;
      float: left;
      margin-right: 1rem;
      margin-bottom: -.75em;
    }    

    &:after {
      content: "\201E";
      bottom: 0;
      right: 0;
      transform: translateY(2px);
    }

    * {
      font-family: "Jost", sans-serif !important;
    }
  }  
  
  p {
    font-weight: 400;
    margin-bottom: 0;
    strong {
      font-weight: inherit;
    }
  }

  .article-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;

    span {
      display: block;
      width: .5rem;
      height: .5rem;
      margin: .5rem;
      border-radius: 100%;
      background-color: $clrAzure;
    }
  }

  button {
    width: 100%;
    line-height: 1;
    margin-top: auto;
  }
}

.modal-full-testimonial {
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  justify-content: center;
  align-items: center;

  &.is-open {
    display: flex !important;
  }

  .modal-overlay {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .modal-body {
    position: relative;
    z-index: 10;
    width: 600px;
    max-height: calc(100% - 5rem);
    max-width: calc(100% - 2.5rem);
    display: flex;
    flex-direction: column;
    border: 1px solid $clrAzure;
    box-shadow: 3px 3px 0 3px $clrAzure;
    padding: 1.5rem;
    align-self: center;
    background-color: #fff;
  }

  header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid $clrLightBlue;
    margin-bottom: 1rem;

    figure {
      flex-shrink: 0;
      padding-right: 1rem;
      border-right: 1px solid $clrAzure;
      margin-right: 1rem;
    }

    img {
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      object-fit: cover;      
      flex-shrink: 0;
    }

    h3 {
      @include h5;
      margin-bottom: 0;
    }
  }

  blockquote {
    @include p4;
    color: $clrBlack;
    background-color: #fff;
    margin-top: .5rem;
    position: relative;   
    padding-right: 1rem;
    @include scrollY;

    p {
      margin-bottom: 1.5rem;
    }

    a {
      text-decoration: none;
      color: $clrLightBlue;
      font-weight: 700;
    }
    
    * {
      font-family: "Jost", sans-serif !important;
    }
  }

  hr {
    width: 100%;
    height: 0;
    border-top: 1px solid $clrLightBlue;
    margin: 1.5rem 0;
  }

  button {
    margin-right: auto;
    margin-left: auto;
    @include p5;
    min-width: 150px;
  }
}