/**
 * A list of useful mixins...
 */
 // vertical scrollbar
@mixin scrollY {
  overflow-y: auto;
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: $clrAzure transparent;

  &::-webkit-scrollbar {
    width: 4px;
    margin-right: -2rem;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $clrAzure;
    border-radius: 4px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $clrAzure;
  }

  &:hover {
    @media (hover: hover) {
      cursor: grab;
    }
  }

  &:active {
    @media (hover: hover) {
      cursor: grabbing;
    }
  }
}