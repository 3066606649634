/**
 * CF7 form
 */
.wpcf7-form {
  label {
    display: block;
    width: 100%;
    @include p4;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .input-class {
    display: block;
    width: 100%;
    height: auto;
    padding: .6em 1em;
    @include p4;
    color: $clrBlack;
    border-width: 0 0 1px 0;
    border-color: $clrLightBlue;
    border-style: solid;
  }

  input {
    &[type="text"],
    &[type="email"],
    &[type="password"] {
      @extend .input-class;

      &::placeholder {
        @include p4;
        color: rgba($clrBlack, .5);
      }
    }
  }

  select, textarea {
    @extend .input-class;
    
    &::placeholder {
      @include p4;
      color: rgba($clrBlack, .5);
    }
  }

  input[type="submit"] {
    margin-top: 2rem;
    min-width: 150px;
    @extend .btn-primary;
    @media screen and (max-width: 767px) {
      margin-top: 1rem;
    }
  }

  a {
    color: $clrBlack;
    text-decoration: underline;
    transition: all ease-in-out .2s;

    &:hover {
      @media (hover: hover) {
        color: $clrLightBlue;
      }
    }    
  }

  .wpcf7-list-item {
    margin-left: 0;
  }

  .wpcf7-not-valid-tip {
    display: block;
    line-height: 1.2;
    font-size: .8rem;
    margin-top: .5rem;
  }
}