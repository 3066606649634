.page-about {
  .section-about {
    img {
      max-width: 400px;
      margin-left: auto;
      @media screen and (max-width: 767px) {
        margin-right: auto;
      }
    }
  }

  .swiper.swiper-about {
    flex: 0;
    width: 100%;
  }
}