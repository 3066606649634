.content-section {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1.5rem;
  margin: 4rem 0;
  width: 100%;

  @media screen and (max-width: 767px) {
    display: block;
    margin: 2rem 0;
    padding: 0 1.25rem;
  }

  > * {
    flex: 1 1 50%;

    &:first-child {
      padding-right: 1rem;
      
      @media screen and (max-width: 767px) {
        padding: 0;
        margin-bottom: 1.25rem;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  
    &:last-child {
      padding-left: 1rem;
      @media screen and (max-width: 767px) {
        padding: 0;
      }
    }
  }

  header {
    flex-basis: 100%;
    padding-right: 0;
  }

  .section-desc {
    .title-sep {
      margin-top: 2rem;
    }

    p { 
      max-width: 600px;   
      &:first-of-type {
        &:first-child {
          &:first-letter {
            @include h1;        
            display: inline-block;
            text-transform: capitalize;
            margin-right: .3rem;
          } 
        }
      }
    } 
  }  

  .section-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1.5rem;
    width: calc(100% + 1rem);
    margin-left: -.5rem;
    margin-right: -.5rem;

    a, button {
      margin: .5rem;
      flex: 0 0 auto;
    }
  }
}

.content-section {
  &.section-full-w {
    display: block;    ;

    > * {
      width: 768px;
      max-width: calc(100% - 2.5rem);
      margin-right: auto;
      margin-left: auto;

      @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 100%;
      }

      &:first-child {
        padding-right: unset;
      }
    
      &:last-child {
        padding-left: unset;
      }
    } 

    header {
      max-width: unset;
    }
    
    p, ul, a {
      @include p3;
      @media screen and (max-width: 767px) {
        @include p4;
      }
    }

    &:first-of-type {
      p {
        &:first-of-type {
          &:first-child {
            &:first-letter {
              @include h1;        
              display: inline-block;
              text-transform: capitalize;
              margin-right: .3rem;
            } 
          }
        }
      }      
    }    
  }

  &.section-swiper {
    background-color: rgba($clrAzure, .6);
    padding: 3.5rem 1.5rem;

    @media screen and (max-width: 767px) {
      padding: 2rem 1.25rem;
    }

    .title-sep {
      margin-bottom: 2rem;
      @media screen and (max-width: 767px) {
        margin-bottom: 1.5rem;
      }
    }

    .swiper {
      padding-left: 0;
    }
  
    .swiper-buttons {
      margin-bottom: 0;
    }
  }
  
  &.section-table {
    header {
      margin-bottom: 1.5rem;
    }
    h2 {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      .fa-file-pdf {
        @include h2;
        margin-right: 1rem;
      }
    }
    table {
      padding-left: 0;

      tr {
        &:nth-of-type(odd) {
          background-color: rgba($clrAzure, .6);
        }

        &:hover {
          @media (hover: hover) {
            opacity: .5;
          }   
        }  
      }

      a {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: .5em 1em;
        text-decoration: unset;
        color: $clrBlack;
        text-transform: uppercase;
        font-weight: 500;
        @include p5;
        line-height: 1.2;              

        span {
          margin-right: auto;
          padding-right: 1rem;
        }      
      }
    }    
  }

  &.section-inline {
    &:nth-of-type(even) {
      background-color: rgba($clrAzure, .6);
      padding: 3.5rem 1.5rem;
      @media screen and (max-width: 767px) {
        padding: 2rem 1.25rem;
      }
    }

    header {
      margin-bottom: 1.5rem;
    }
    
    h2 {
      max-width: 600px;
      @media screen and (max-width: 767px) {
        max-width: unset;
      }
    }

    article {
      width: 100%;
      flex-basis: 100%;
      padding-left: 0;    
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      @media screen and (max-width: 767px) {
        display: block;
      }
      
      > * {
        flex: 1 1 50%;

        &:first-child {
          padding-right: 1rem;
          @media screen and (max-width: 767px) {
            padding: 0;
            margin-bottom: 1.5rem;
          }
        }

        &:last-child {
          padding-left: 1rem;
          @media screen and (max-width: 767px) {
            padding: 0;
          }
        }
      }
    }

    .text {
      @include p3;

      @media screen and (max-width: 767px) {
        @include p4;
      }

      &:first-child {
        &:last-child {
          padding: 0;
        }
      }
      &:last-child {
        padding-left: 1rem;

        @media screen and (max-width: 767px) {
          padding-left: 0;
        }
      }
    }    

    .swiper {
      align-self: flex-start;
      width: 50%;
      max-width: 50%;
      flex-grow: 0;
      margin-right: 0;

      @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 100%;
      }

      &:first-child {
        margin-right: auto;
      }
    }

    .swiper-wrapper, .swiper-slide {
      width: 100%;      
    }

    .swiper-wrapper {
      height: auto;
    }

    .swiper-slide {
      height: 100%;
      // patch for border-right
      padding-right: 2px;

      figure, img {        
        height: 100%;
      }

      figure {
        border: 1px solid $clrLightBlue;     
        margin-bottom: 1rem;
      }

      img {
        aspect-ratio: 16/9;   
        background-color: #fff;
      }

      button {
        font-size: .75rem;
      }
    }
  }
}