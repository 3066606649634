.main-nav {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 1.5rem;
  right: 1.5rem;
  padding: 1.5rem 0;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $clrLightBlue;
  max-width: 1920px;   
  margin: 0 auto;  

  @media screen and (max-width: 767px) {
    left: 1.25rem;
    right: 1.25rem;
  }

  &:before {
    content: "";
    background-color: #fff;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100vw;
    height: 100%;
    pointer-events: none;
  }

  > * {
    position: relative;
    z-index: 10;
  }
}

.site-logo-wrap {
  display: block;
  width: 200px;
  flex-shrink: 0;
}

.menu-header-menu-container {
  
  @media screen and (max-width: 991px) {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0, 100%);
    width: 100%;
    padding: 2rem 0;
    display: none;
  }

  &.is-open {
    @media screen and (max-width: 991px) {
      display: block;
    } 
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    background-color: #fff;
    display: none;
    border-bottom: 4px solid $clrLightBlue;
    
    @media screen and (max-width: 991px) {
      display: block;
    }
  }
}

.nav-voicelist {
  list-style: none;
  display: flex;  
  align-items: center;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    position: relative;
    z-index: 10;    
    max-height: 65vh;
    @include scrollY;
  }

  a {
    display: block;
    @include p5;
    line-height: 1;
    color: $clrBlack;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    letter-spacing: .5px;
    padding: 0 1rem;

    @media screen and (max-width: 1279px) {
      padding: 0 .5rem;
    }
    
    @media screen and (max-width: 991px) {
      @include p3;
      text-align: left;
      padding: .3rem 0;
      line-height: 1.2;
    }
  }

  li {
    @media screen and (max-width: 991px) {
      width: 100%;
      padding: 0 0 .3rem;
      margin: 0 0 .3rem;
      border-bottom: 1px solid $clrAzure;
    }

    &.current-menu-item {
      a {
        font-weight: 700;
      }
    }
    
    &:last-of-type {
      @media screen and (max-width: 991px) {
        border-bottom: 0;
      }

      a {
        padding-right: 0;
      }
    }
  }
}

.btn-toggle-mobile-nav {
  display: none;
  
  @media screen and (max-width: 991px) {
    display: block;
    width: 4rem;
    height: 2.2rem;
    border: 0;
    background-color: $clrLightBlue;
    color: #fff;      
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out .2s;
  }

  &.is-open {
    span {
      display: none;
    }

    .fa-xmark {
      display: block;
    }
  }

  &:hover {
    @media (hover: hover) {
      cursor: pointer;
      opacity: .75;
    }
  }

  span {
    display: block;
    width: 50%;
    height: 0;
    background-color: #fff;
    margin: 2px 0;
    border-top: 2px solid #fff;
  }

  .fa-xmark {
    display: none;
  }
}