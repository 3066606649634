.page-news {
  header {
    margin-bottom: 0;
    img {
      margin-bottom: inherit;
    }    
  }

  .entries-wrap {
    &.featured-news {
      margin-top: 0;
    }
  }
}