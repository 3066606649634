.entries-wrap {
  padding: 0 1.5rem;
  margin: 4rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  
  @media screen and (max-width: 767px) {
    display: block;
    margin: 2rem 0 2.5rem;
    padding: 0 1.25rem;
  }

  &.featured-news {
    display: block;
    background-color: rgba($clrAzure, .6);
    padding: 4rem 1.5rem;
    margin: 3rem 0 4rem;

    @media screen and (max-width: 767px) {
      padding: 2rem 1.25rem;
      margin-bottom: 2.5rem;
    }

    header {
      margin-bottom: 1.5rem;
    }

    .swiper-buttons {
      margin-bottom: 0;
      @media screen and (max-width: 767px) {
        margin-top: .5rem;
      }
    }

    .swiper {
      height: auto;
    }
  
    .swiper-wrapper {
      align-items: stretch;
      height: auto;
      padding-bottom: 2rem;
    }

    .swiper-slide {      
      height: inherit;
    }    

    .entry {
      height: 100%;
      margin: 0;

      a {        
        border: 1px solid rgba($clrLightBlue, .2);
        box-shadow: 3px 3px 0 3px rgba($clrLightBlue, .2);       
        background-color: #fff;     
      }      
    }    
  }

  &:not(.featured-news) {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-right: -1rem;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }

    .entries-header {
      padding-left: 1rem;
      padding-right: 1rem;
      
      @media screen and (max-width: 767px) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

.entries-header {
  flex-basis: 100%;
}

.entry {
  flex: 0 0 calc((100% / 5) - 2rem);
  margin: 1rem;

  @media screen and (max-width: 1279px) {
    flex: 0 0 calc((100% / 3) - 2rem);
  }

  @media screen and (max-width: 991px) {
    flex: 0 0 calc(50% - 2rem);
  }

  @media screen and (max-width: 767px) {
    margin: 0 0 2rem;
  }

  &.is-hidden {
    display: none;
  }

  &:hover {
    @media (hover: hover) {
      button, img {
        opacity: .75;
      }
    }
  }

  > a {
    height: 100%;    
    border: 1px solid $clrAzure;
    box-shadow: 3px 3px 0 3px $clrAzure;
    display: block;
    text-decoration: none;
    color: $clrBlack;
    display: flex;
    flex-direction: column;
  }

  img {
    transition: all ease-in-out .2s;
    aspect-ratio: 16/9;
    object-fit: cover;
  }

  figcaption {
    margin: 0 0 1rem;
    padding: 1rem;
    border-top: 1px solid $clrAzure;
  }

  button {
    @include p6;
    width: calc(100% - 2rem);
    box-shadow: unset;
    border: 0;
    margin: auto auto 1.5rem;
    transition: all ease-in-out .2s;
    @extend .btn-primary;

    &:hover {
      @media (hover: hover) {
        cursor: pointer;
      }
    }
  }

  .entry-date {
    @include p6;
    font-weight: 400;
    display: block;
    margin-bottom: .5rem;
  }

  .entry-title {
    @include p2;
  }
}

.entries-pagination {
  width: 100%;
  max-width: 1920px;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 4rem 0 0;

  .page-index {
    border: 1px solid $clrLightBlue;
    @extend .btn-primary;
    margin: 5px;
    cursor: pointer;

    &:not(.is-current) {
      background-color: #fff;
      color: $clrLightBlue;
    }
  }
}