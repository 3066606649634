.site-footer {
  padding: 0 1.5rem;

  @media screen and (max-width: 767px) {
    padding: 0 1.25rem;
  }
  
  .footer-infos {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 4rem;

    @media screen and (max-width: 991px) {
      margin-bottom: 1.2rem;
    }

    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  .footer-info {
    @media screen and (max-width: 991px) {
      margin-right: 2rem;
      margin-bottom: 1.2rem;
    }

    @media screen and (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }

  .footer-voicelist {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 2;
    max-height: calc(1.3rem * 5);

    @media screen and (max-width: 991px) {
      width: fit-content;
    }

    li {
      padding-right: 2.5rem;
      @media screen and (max-width: 991px) {
        padding-right: 1.5rem;
        width: fit-content;
      }
    }
  }

  ul {
    list-style: none;
    display: block;
  }

  h3 {
    margin-bottom: .3rem;
    @media screen and (max-width: 767px) {
      line-height: 1;
    }
  }

  a {
    color: $clrBlack;
    text-decoration: none;
    transition: all ease-in-out .2s;

    &:hover {
      color: $clrLightBlue;
    }
  }

  aside {
    width: 100%;
    padding: 2rem 0 1.8rem;
    position: relative;

    @media screen and (max-width: 767px) {
      padding: 1.25rem 0;
    }

    &:before {
      content: "";
      background-color: rgba($clrAzure, .4); 
      position: absolute;
      z-index: 0;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      max-width: 1920px;
      height: 100%;      
    }

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 10;
      @include p5;
      text-transform: uppercase;

      @media screen and (max-width: 767px) {
        display: block;
      }
    }

    li {
      line-height: 1; 
      
      @media screen and (max-width: 767px) {
        margin-bottom: .5rem;
        line-height: 1.2;
        max-width: 95%;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
      }

      &:last-child {
        @media screen and (max-width: 767px) {
          margin-bottom: 0;
        }
      }

      strong {
        font-weight: 600;
      }
    }
  }
}